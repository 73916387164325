import React from "react"
import ThreeTemplate from "../../components/ThreeTemplate"

class Scan extends React.Component {
  render() {
    return (
      // <ThreeTemplate imp={import("../../Scenes/ScanScene")}></ThreeTemplate>
      <div>scan</div>
    )
  }
}

export default Scan
